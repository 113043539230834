<template>
  <div class="page page-box">
    <!-- 页头 -->
    <header class="page-header">
      <el-form :inline="true" :model="listQuery" class="demo-form-inline">
        <el-form-item label="时间选择：">
          <div class="block">
            <el-date-picker v-model="dateValue" type="daterange" align="right" :clearable="false" unlink-panels range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" :picker-options="pickerOptions" />
          </div>
        </el-form-item>
        <el-form-item label="学员姓名：">
          <el-input v-model="listQuery.Name" placeholder="学员姓名" />
        </el-form-item>
        <el-form-item>
          <el-button v-preventReClick @click="handleSearch" class="discoloration"> 查询 </el-button>
          <el-button v-preventReClick @click="resetSearch" class="plain-button"> 重置 </el-button>
        </el-form-item>
      </el-form>
    </header>
    <!-- 主体 -->
    <main class="page-main-main" ref="main">
      <!-- 列表渲染 -->
      <el-table v-loading="loading" :data="tableList" border fit stripe>
        <el-table-column prop="Name" label="姓名" align="center" />
        <el-table-column prop="Attendance" label="出勤" align="center" />
        <el-table-column prop="Thing" label="事假" align="center" />
        <el-table-column prop="Sick" label="病假" align="center" />
        <el-table-column prop="Truancy" label="旷课" align="center" />
      </el-table>
      <!-- 分页 -->
      <footer class="footer-box">
        <pagination v-show="total > 0" :total="total" :page.sync="listQuery.PageNo" :limit.sync="listQuery.PageSize" @pagination="getClassStudentCheckList" />
      </footer>
    </main>
  </div>
</template>

<script>
import { getClassStudentCheckList } from "@/service/educational";
import pagination from '@/components/pagination/pagination.vue';  // 引入分页
import { deepClone } from '@/utils/index';  // 引入深拷贝
import moment from 'moment';

const ListQuery = { // 查询数据
  PageNo: 1,
  PageSize: 50,
  Name: '',
};
export default {
  name: "StudentAttendance",
  components: {
    pagination,
  },
  data() {
    return {
      moment,
      pickerOptions: {
        disabledDate(time) { //时间禁用
          return time.getTime() > Date.now();
        },
      },
      listQuery: deepClone(ListQuery),
      tableList: [],  // 表格数据
      total: 0, // 表格总条数
      loading: false, // 加载状态
      Id: '',
      dateValue: [new Date(new Date().getTime() - 29 * 24 * 60 * 60 * 1000), new Date()],
    }
  },
  created() {
    this.Id = this.$route.query.id;
    this.getClassStudentCheckList();
  },
  methods: {
    /**
      * 获取学员考勤信息列表数据
      */
    async getClassStudentCheckList(data) {
      if (data) {
        this.listQuery.PageNo = data.page;
        this.listQuery.PageSize = data.limit;
      }
      this.loading = true;
      let res = await getClassStudentCheckList({
        ...this.listQuery,
        StartDate: Array.isArray(this.dateValue) && this.dateValue.length > 0 && parseInt(new Date(moment(this.dateValue[0]).format('YYYY-MM-DD 00:00:00')).getTime() / 1000),
        EndDate: Array.isArray(this.dateValue) && this.dateValue.length > 0 && parseInt(new Date(moment(this.dateValue[1]).format('YYYY-MM-DD 23:59:59')).getTime() / 1000),
        ClassId: this.Id
      });
      this.loading = false;
      this.tableList = res.DataList;
      this.total = res.Total;
    },

    /**
     * 页头-查询
     */
    handleSearch() {
      this.listQuery.PageNo = 1;
      this.listQuery.Name = this.listQuery.Name && this.listQuery.Name.trim();
      this.getClassStudentCheckList();
    },

    /**
     * 页头-重置
     */
    resetSearch() {
      this.listQuery = deepClone(ListQuery);
      this.dateValue = [new Date(new Date().getTime() - 29 * 24 * 60 * 60 * 1000), new Date()];
      this.getClassStudentCheckList();
    },
  },
}
</script>
<style lang='scss' scoped>
// /deep/.el-table__body tr,
// .el-table__body td {
//   height: 50px !important;
// }
</style>