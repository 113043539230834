<template>
  <div class="page-box page-padding">
    <div class="go-class">
      <el-button @click="()=> $router.go(-1)" class="plain-button">
        <div class="img-center"> <img src="@/assets/return.png" alt="返回" class="add-img"> <span>返回</span></div>
      </el-button>
    </div>
    <div class="class-content" v-loading='loading'>
      <el-tabs class="tabs-box" v-model="activeName" @tab-click="handleClickTab">
        <el-tab-pane label="班级考勤信息" name="classAttendance">
          <classAttendance v-if="activeName==='classAttendance'" />
        </el-tab-pane>
        <el-tab-pane label="学员考勤信息" name="studentAttendance">
          <studentAttendance v-if="activeName==='studentAttendance'" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import classAttendance from '@/views/educational/attendancesComponent/classAttendance.vue';
import studentAttendance from '@/views/educational/attendancesComponent/studentAttendance.vue';

export default {
  name: 'AttendanceDetail',
  components: {
    classAttendance,
    studentAttendance,
  },
  data() {
    return {
      Id: "",
      activeName: 'classAttendance', // tab选中标识
      loading: false,
    }
  },

  created() {

  },

  methods: {

    /**
     * 改变tab选中
     */
    handleClickTab() {

    }
  }
}
</script>
<style lang='scss' scoped>
.class-content {
  // flex: 1;
  display: flex;
  flex-direction: column;
}
.tabs-box {
  // flex: 1;
  // min-height: 400px;
  display: flex;
  flex-direction: column;
  /deep/.el-tabs__content {
    flex: 1;
    // min-height: 400px;
    height: 0;
    .el-tab-pane {
      min-height: 100%;
      // height: 540px;
    }
  }
}

/deep/.el-tabs__header {
  padding-left: 32px !important;
}
// .page-padding {
//   display: flex;
//   overflow-x: hidden;
//   flex-direction: column;
// }
</style>