<template>
  <div class="page page-box">
    <!-- 页头 -->
    <header class="page-header">
      <el-form :inline="true" :model="listQuery" class="demo-form-inline">
        <el-form-item label="开始时间：">
          <div class="block">
            <el-date-picker v-model="dateValue" type="daterange" align="right" :clearable="false" unlink-panels range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" :picker-options="pickerOptions" />
          </div>
        </el-form-item>
        <el-form-item>
          <el-button v-preventReClick @click="handleSearch" class="discoloration"> 查询 </el-button>
          <el-button v-preventReClick @click="resetSearch" class="plain-button"> 重置 </el-button>
        </el-form-item>
      </el-form>
    </header>
    <!-- 主体 -->
    <main class="page-main-main" ref="main">
      <!-- 列表渲染 -->
      <el-table v-loading="loading" :data="tableList" border fit stripe>
        <el-table-column label="上课时间" align="center" :show-overflow-tooltip="true" width="200px">
          <template slot-scope="{ row }">
            {{ (row.ClassStartDate && moment(row.ClassStartDate).format('YYYY-MM-DD HH:mm'))+'-'+(row.ClassEndDate && moment(row.ClassEndDate).format('HH:mm')) || '' }}
          </template>
        </el-table-column>
        <el-table-column label="训练进度" align="center" width="100px">
          <template slot-scope="{row}">
            第{{row.Schedule}}课时
          </template>
        </el-table-column>
        <el-table-column label="训练主题" align="center" prop="Theme">
        </el-table-column>
        <el-table-column prop="Attendance" label="出勤" align="center" width="80px" />
        <el-table-column prop="Thing" label="事假" align="center" width="80px" />
        <el-table-column prop="Sick" label="病假" align="center" width="80px" />
        <el-table-column prop="Truancy" label="旷课" align="center" width="80px" />
        <el-table-column label="操作" align="center" width="80px">
          <template slot-scope="{ row }">
            <el-button type="text" @click="readDetail(row)"> 查看详情 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <footer class="footer-box">
        <pagination v-show="total > 0" :total="total" :page.sync="listQuery.PageNo" :limit.sync="listQuery.PageSize" @pagination="getClassCourseCheckList" />
      </footer>
    </main>
    <!-- 查看详情 -->
    <el-dialog title="查看详情" :visible.sync="readVisible" width="40%" :close-on-click-modal='false'>
      <div class="page page-box">
        <el-row>
          <el-col :span="12">
            <span>上课日期：</span><span>{{ readAttendanceForm.ClassStartDate}}-{{ moment(readAttendanceForm.ClassEndDate).format('HH:mm') }}</span>
          </el-col>
          <el-col :span="12">
            <span>学生人数：</span><span>{{ dateList.MemberCount }}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <span>训练主题：</span><span>{{ readAttendanceForm.Theme }}</span>
          </el-col>
          <el-col :span="12">
            <span>训练进度：</span><span>第{{ readAttendanceForm.Schedule }}课时</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <span>出勤：</span><span>{{ readAttendanceForm.Attendance }}</span>
          </el-col>
          <el-col :span="6">
            <span>事假：</span><span>{{ readAttendanceForm.Thing }}</span>
          </el-col>
          <el-col :span="6">
            <span>病假：</span><span>{{ readAttendanceForm.Sick }}</span>
          </el-col>
          <el-col :span="6">
            <span>旷课：</span><span>{{ readAttendanceForm.Truancy }}</span>
          </el-col>
        </el-row>
        <main class="page-main" ref="main">
          <el-table v-loading="loading" :data="tableData" height="200" border fit stripe class="detail-table">
            <el-table-column prop="Name" label="姓名" align="center" :show-overflow-tooltip="true" />
            <el-table-column prop="Attendance" label="出勤" align="center">
              <template slot-scope="{ row}">
                <div v-if="row.Status === 1"> <img src="@/assets/done.png" alt="done" class="done-img"></div>
              </template>
            </el-table-column>
            <el-table-column prop="Thing" label="事假" align="center">
              <template slot-scope="{ row}">
                <div v-if="row.Status === 2"> <img src="@/assets/done.png" alt="done" class="done-img"></div>
              </template>
            </el-table-column>
            <el-table-column prop="Sick" label="病假" align="center">
              <template slot-scope="{ row}">
                <div v-if="row.Status === 3"> <img src="@/assets/done.png" alt="done" class="done-img"></div>
              </template>
            </el-table-column>
            <el-table-column prop="Truancy" label="旷课" align="center">
              <template slot-scope="{ row}">
                <div v-if="row.Status === 4"> <img src="@/assets/done.png" alt="done" class="done-img"></div>
              </template>
            </el-table-column>
          </el-table>
        </main>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getClassCourseCheckList } from "@/service/educational";
import { getClassCourseCheckDetail, getAttendanceList } from '@/service/educational';
import pagination from '@/components/pagination/pagination.vue';  // 引入分页
import { deepClone } from '@/utils/index';  // 引入深拷贝
import moment from 'moment';

const ListQuery = { // 查询数据
  PageNo: 1,
  PageSize: 50,
};
export default {
  name: "ClassAttendance",
  components: {
    pagination,
  },
  data() {
    return {
      moment,
      listQuery: deepClone(ListQuery),
      tableList: [],  // 表格数据
      tableData: [],
      total: 0, // 表格总条数
      loading: false, // 加载状态
      pickerOptions: {
        disabledDate(time) { //时间禁用
          return time.getTime() > Date.now(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1)
        },
      },
      Id: "",
      readVisible: false, // 详情弹窗状态
      readAttendanceForm: {},
      dateValue: [new Date(new Date().getTime() - 29 * 24 * 60 * 60 * 1000), new Date()],
      dateList: {},
    }
  },
  created() {
    this.Id = this.$route.query.id;
    this.getClassCourseCheckList();
    this.getAttendanceList();
  },
  methods: {
    /**
      * 获取班级考勤信息列表数据
      */
    async getClassCourseCheckList(data) {
      if (data) {
        this.listQuery.PageNo = data.page;
        this.listQuery.PageSize = data.limit;
      }
      this.loading = true;
      let res = await getClassCourseCheckList({
        ...this.listQuery,
        StartDate: Array.isArray(this.dateValue) && this.dateValue.length > 0 && parseInt(new Date(moment(this.dateValue[0]).format('YYYY-MM-DD 00:00:00')).getTime() / 1000),
        EndDate: Array.isArray(this.dateValue) && this.dateValue.length > 0 && parseInt(new Date(moment(this.dateValue[1])).getTime() / 1000),
        ClassId: this.Id
      });
      this.loading = false;
      this.tableList = res.DataList;
      this.total = res.Total;
    },
    async getAttendanceList() {
      this.loading = true;
      let res = await getAttendanceList();
      this.dateList = res.DataList.find(item => item.Id === this.Id);
    },

    /**
      * 获取班级考勤信息详情数据
      */
    async getClassCourseCheckDetail(CourseId) {
      let res = await getClassCourseCheckDetail({ CourseId });
      this.tableData = res;
    },

    /**
     * 页头-查询
     */
    handleSearch() {
      this.listQuery.PageNo = 1;
      this.getClassCourseCheckList();
    },

    /**
     * 页头-重置
     */
    resetSearch() {
      this.listQuery = deepClone(ListQuery);
      this.dateValue = [new Date(new Date().getTime() - 29 * 24 * 60 * 60 * 1000), new Date()];
      this.getClassCourseCheckList();
    },

    /**
     * 操作-查看
     */
    readDetail(row) {
      this.readVisible = true;
      this.getClassCourseCheckDetail(row.CourseId);
      this.readAttendanceForm = { ...row };
    }
  },
}
</script>
<style lang='scss' scoped>
.el-row {
  margin: 10px 0;
  margin-left: 24px;
}
.detail-table {
  height: 284px !important;
}
/deep/.el-table__row {
  height: 20px !important;
}

.done-img {
  width: 16px;
  height: 16px;
}
</style>